<template>
    <div class="meeting-rooms">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Salas de Juntas',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabrica de muebles para salas de juntas: 30 años de experiencia fabricando muebles para salas de juntas en bucaramanga' },
			{ name: 'keywords', content:'industrias pico, muebles oficina, muebles oficina Bucaramanga, muebles para oficina Bucaramanga, estantería metálica, estanterías metálicas, fabricas de estanterías metálicas, divisiones para oficina Bucaramanga, escritorios gerenciales Bucaramanga, escritorios para gerencia, Archivadores metálicos, fabrica de muebles para oficina, sillas para oficinas Bucaramanga, diseño de oficinas Bucaramanga, muebles para recepción Bucaramanga' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Salas de Juntas'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Fabrica de muebles para salas de juntas: 30 años de experiencia fabricando muebles para salas de juntas en bucaramanga'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Salas de Juntas'},
			{name: 'twitter:description', content: 'Fabrica de muebles para salas de juntas: 30 años de experiencia fabricando muebles para salas de juntas en bucaramanga' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Salas de Juntas'},
			{itemprop: 'description', content: 'Fabrica de muebles para salas de juntas: 30 años de experiencia fabricando muebles para salas de juntas en bucaramanga' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    data() {
        return {
            data_products: {
                title_page: 'Salas de juntas',
                types: [
                    { text: 'Todos', value: 'todos' }
                ],
                products: [
                    {
                        name: 'Sala de juntas 1',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/salas-de-juntas/productos/juntas_1.jpg',
                        slides: [
                            'mobiliario-de-oficina/salas-de-juntas/productos/juntas_1.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    },
                    {
                        name: 'Sala de juntas 2',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/salas-de-juntas/productos/juntas_2.jpg',
                        slides: [
                            'mobiliario-de-oficina/salas-de-juntas/productos/juntas_2.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    }
                ],
            }
        }
    }
}
</script>